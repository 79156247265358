<template>
  <div >

    <el-card style="width: 600px; margin:150px auto ">
      <b style="margin:10px auto">不好意思,页面走丢了哦~</b>

      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <a href="http://120.27.250.188:8080/front/home" style="color: #9D2A2A;font-size: large;margin:10px auto 5px" >点击返回网站首页</a>

    </el-card>

  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>


</style>
